import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Logo from "./logo"
import Navigation from "./navigation"

import "../assets/scss/style.scss"
import Footer from "./footer"

const query = graphql`
query LayoutQuery {
  site {
    siteMetadata {
      siteTitle: title
    }
  }
}
`

const Layout = ({ children, className }) => {

  const { site } = useStaticQuery(query)
  const { siteTitle } = site.siteMetadata

  /*return (
    <div className="primary-container">
      <main className={"container " + className}><h1 style={{textAlign: "center"}}>Vocablurry will be returning soon...</h1></main>
    </div>
  )

  if (false)*/
    return (
      <div className="primary-container">
        {false && <Header>
          <Logo title={siteTitle}/>
          <Navigation/>
        </Header>}
        <main className={"container " + className}>
          {children}
        </main>
        {false && <Footer/>}
      </div>
    )
}

export default Layout

